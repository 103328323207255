import type { RefSchemas, ViewMode } from '@wix/thunderbolt-becky-types'
import type { StylableEditor } from '@wix/stylable-panel-drivers'
import { initDSCarmiInstances } from 'thunderbolt-viewer-manager'
import type { DSCarmiFactoryParams, DSRendererModel, Manifest } from 'thunderbolt-viewer-manager-types'
import type { ViewerAPI } from '@wix/viewer-manager-interface'
import { createFeaturesLoader } from '@wix/thunderbolt-features'
import { ComponentLibraries } from '@wix/thunderbolt-components-loader'
import { Identifier, IocContainer } from '@wix/thunderbolt-ioc'
import { featuresLoaders } from 'ds-features-loaders'
import type { Environment } from '@wix/thunderbolt-environment'
import { initThunderboltDS } from './initThunderboltDs'
import { BrowserWindow, FetchFn, ILogger, LOADING_PHASES } from '@wix/thunderbolt-symbols'
import type { BeckyModel, ComponentsNativeMappers, ComponentsSkinParams } from '@wix/thunderbolt-becky-root'
import { mergeWithExternalSkinsInfo } from '@wix/thunderbolt-becky-root'
import { createComponentsPreviewRegistryCSR } from '@wix/thunderbolt-components-registry/preview'
import { ClientRenderResponse } from 'feature-react-renderer'
import { FetchApi, isExperimentOpen, AsyncRunAndReport } from '@wix/thunderbolt-commons'
import { Div } from '@wix/thunderbolt-app-commons'
import { DSViewerApiFactoryParams } from './dsClientRunner'
import { applyPolyfillsIfNeeded, initWixCustomElementsRegistry } from './customElementsUtils'
import { createStylableFactory } from './createStylableFactory'
import { pagesModelsRegistrar } from './pagesModelsRegistrar'
import { DSCarmi } from 'thunderbolt-ds-carmi-root'
import { createDomReadyPromise } from 'feature-thunderbolt-initializer'
import { getEditorNameFromEditorParams } from './helper'
import { isMobileAppBuilder } from '@wix/thunderbolt-ssr-api'
import { WixMap } from './WixMap'

export type DsApiFactoryEnv = {
	createComponentsPreviewRegistryPromise: Promise<{
		createComponentsPreviewRegistryCSR: typeof createComponentsPreviewRegistryCSR
	}>
	viewerManagerModulePromise: Promise<{
		initDSCarmiInstances: typeof initDSCarmiInstances
	}>
	createStylableFactoryPromise: Promise<{
		createStylableFactory: typeof createStylableFactory
	}>
	logger: ILogger
	componentLibraries?: ComponentLibraries
	container: IocContainer
	window: NonNullable<BrowserWindow> & {
		WixMap: typeof WixMap
		viewerSource: string
		viewerBase: string
		manifest: Manifest
	}
	isViewerFragment: boolean
	schemas: RefSchemas
	viewMode: ViewMode
}

export type DsApis = {
	getViewerAPI: () => ViewerAPI
	getModule: <T>(identifier: Identifier) => T
	getNamedModule: <T>(arg: { identifier: Identifier; name: string }) => T
	render: (arg: {
		target: HTMLElement
		rootCompId?: string
		cssRootCompIds?: Array<string>
	}) => Promise<ClientRenderResponse>
	appDidMount: () => void
	appWillUnmount: () => void
	initCustomElements: () => Promise<void>
	dsCarmi?: DSCarmi
}

const toDSCarmiInstanceFactoryParams = (
	{
		runningExperiments,
		dataFixerExperiments,
		platformAppsExperiments,
		languageCode,
		isResponsive,
		siteOwnerCoBranding,
		useSandboxInHTMLComp,
		serviceTopology,
		mediaAuthToken,
		urlMappings,
		userId,
		origin,
	}: DSViewerApiFactoryParams,
	clientWorkerUrl: string,
	fetchFn: FetchFn,
	externalBaseUrl: string,
	url: string,
	queryParams: string,
	cookie: string,
	componentsMappers: ComponentsNativeMappers,
	componentsSkinParams: ComponentsSkinParams,
	stylableFactoryPromise: Promise<() => StylableEditor>,
	logger: ILogger,
	isViewerFragment: boolean,
	schemas: RefSchemas,
	viewMode: ViewMode
): DSCarmiFactoryParams => ({
	dsRendererModel: {
		runningExperiments: runningExperiments as DSRendererModel['runningExperiments'],
		dataFixerExperiments,
		siteOwnerCoBranding,
		platformAppsExperiments: platformAppsExperiments as DSRendererModel['platformAppsExperiments'],
		languageCode,
		siteMetaData: {
			adaptiveMobileOn: isViewerFragment && viewMode === 'mobile',
			isResponsive,
		},
		useSandboxInHTMLComp,
		urlMappings,
		mediaAuthToken,
		userId,
	},
	dsServiceTopology: serviceTopology,
	clientWorkerUrl,
	componentsMappers,
	componentsSkinParams,
	fetchFn,
	externalBaseUrl,
	url,
	queryParams,
	cookie,
	stylableFactoryPromise,
	logger,
	isViewerFragment,
	schemas,
	editorName: getEditorNameFromEditorParams(origin),
	mainSdksStaticPath: '',
	nonMainSdksStaticPath: '',
	viewMode,
})

const getClientWorkerUrl = async (viewerBase: string, fetchFn: FetchFn) => {
	const workerManifestFileName =
		process.env.NODE_ENV === 'development' ? 'manifest-worker.json' : 'ds-manifest-worker.min.json'
	const workerManifestUrl = `${viewerBase}/${workerManifestFileName}`
	const workerManifest = await fetchFn(workerManifestUrl).then((res) => res.json())
	return workerManifest['clientWorker.js']
}

export const getDsApis = async (
	params: DSViewerApiFactoryParams,
	{
		componentLibraries,
		container,
		window,
		logger,
		createComponentsPreviewRegistryPromise,
		viewerManagerModulePromise,
		createStylableFactoryPromise,
		isViewerFragment,
		schemas,
		viewMode,
	}: DsApiFactoryEnv
): Promise<DsApis> => {
	const { fetchFunction, serviceTopology, wixBiSession, runningExperiments } = params

	const {
		location: { href: url, search: queryParams },
		viewerBase,
		fetch,
		document: { cookie },
	} = window
	const fetchFn = fetch || (fetchFunction as FetchFn)

	logger.phaseStarted(LOADING_PHASES.APPLY_POLYFILLS)
	await applyPolyfillsIfNeeded()
	logger.phaseEnded(LOADING_PHASES.APPLY_POLYFILLS)

	const wixCustomElementsPromise = initWixCustomElementsRegistry(isViewerFragment ? window.parent : window)

	logger.phaseStarted(LOADING_PHASES.WAIT_FOR_IMPORTS)
	const [
		{ createComponentsPreviewRegistryCSR: createComponentsPreviewRegistry },
		{ createStylableFactory: stylableFactory },
	] = await Promise.all([createComponentsPreviewRegistryPromise, createStylableFactoryPromise])
	logger.phaseEnded(LOADING_PHASES.WAIT_FOR_IMPORTS)

	const runAndReport: AsyncRunAndReport = (metric, fn) => {
		return logger.runAsyncAndReport(fn, 'thunderbolt-ds', metric)
	}
	const componentsRegistryPromise = createComponentsPreviewRegistry({
		serviceTopology,
		url,
		experimentalMobileLibrary: isMobileAppBuilder(url, {}),
		useNewStatics: isExperimentOpen('specs.editor-elements.useNewStatics', runningExperiments, url),
		runAndReport,
		isExperimentOpen: (specName: string) => isExperimentOpen(specName, runningExperiments, url),
	})

	const stylableFactoryPromise = componentsRegistryPromise
		.then((registry) => registry.getStylableMetadataURLs())
		.then((componentsStylableMetadataUrls: Array<string>) => {
			const { staticServerFallbackUrl, staticMediaUrl } = serviceTopology
			return stylableFactory({
				componentsStylableMetadataUrls,
				staticServerFallbackUrl,
				staticMediaUrl,
				isExperimentOpen: (specName: string) => isExperimentOpen(specName, runningExperiments, url),
			})
		})

	componentLibraries =
		componentLibraries ||
		componentsRegistryPromise.then((registry) => Promise.all([registry.getComponentsRegistrarAPI()]))
	logger.phaseStarted(LOADING_PHASES.INIT_DS_CARMI)

	const monitorLoadPhase = async (phaseName: string, promiseFn: () => Promise<any>): Promise<any> => {
		logger.phaseStarted(phaseName)
		const result = await promiseFn()
		logger.phaseEnded(phaseName)
		return result
	}

	const [wixCustomElements, { dsCarmi }] = await Promise.all([
		wixCustomElementsPromise,
		(async () => {
			// TODO: Maybe externalBaseUrl can be removed from here altogether.
			const externalBaseUrl = (() => {
				const urlObj = new URL(url)
				return `${urlObj.origin}${urlObj.pathname}`
			})()

			const [
				externalComponentsMappers,
				externalSkinParams,
				clientWorkerUrl,
				viewerManagerModule,
				{ mergeMappers },
				{ getEditorCatharsis },
				{ csmStoreLoader },
			] = await Promise.all([
				monitorLoadPhase(LOADING_PHASES.GET_COMPONENTS_MAPPERS, () =>
					componentsRegistryPromise.then((registry) => registry.getComponentsMappers())
				),
				monitorLoadPhase(LOADING_PHASES.GET_COMPONENTS_MAPPERS, () =>
					componentsRegistryPromise.then((registry) => registry.getComponentsSkinParams())
				),
				monitorLoadPhase(LOADING_PHASES.GET_CLIENT_WORKER, () => getClientWorkerUrl(viewerBase, fetchFn)),
				viewerManagerModulePromise,
				import('@wix/thunderbolt-components/src/mappers'),
				import('@wix/thunderbolt-catharsis-deployable'),
				import('ds-feature-viewer-manager-renderer-model-api'),
			])

			const isBeckyExperimentOpen = (name: keyof BeckyModel['experiments']) =>
				isExperimentOpen(name, runningExperiments, url)
			const componentsMappers: ComponentsNativeMappers = mergeMappers(
				externalComponentsMappers,
				isBeckyExperimentOpen
			)

			csmStoreLoader(container)
			const catharsis = getEditorCatharsis(container)({
				serviceTopology,
				catharsisConstants: {
					componentsMappers,
					experiments: runningExperiments,
					isEditor: true,
					schemas,
					isExperimentOpen: isBeckyExperimentOpen,
				},
				fetchFn,
				runAndReport,
			})

			const mergedSkinParams = mergeWithExternalSkinsInfo(externalSkinParams)

			return viewerManagerModule.initDSCarmiInstances(
				toDSCarmiInstanceFactoryParams(
					params,
					clientWorkerUrl,
					fetchFn,
					externalBaseUrl,
					url,
					queryParams,
					cookie,
					componentsMappers,
					mergedSkinParams,
					stylableFactoryPromise,
					logger,
					isViewerFragment,
					schemas,
					viewMode
				),
				pagesModelsRegistrar,
				catharsis,
				container
			)
		})(),
	])
	logger.phaseEnded(LOADING_PHASES.INIT_DS_CARMI)

	const viewerModel = dsCarmi.viewerModel
	const { experiments, requestUrl } = viewerModel

	const environment: Omit<Environment, 'browserWindow'> & { browserWindow: DsApiFactoryEnv['window'] } = {
		waitForDomReady: createDomReadyPromise,
		// @ts-ignore
		wixBiSession,
		logger,
		// @ts-ignore
		biReporter: {
			reportPageNavigation: () => {},
			reportPageNavigationDone: () => {},
		},
		fetchApi: FetchApi(requestUrl, fetchFn),
		viewerModel: { ...viewerModel, editorSessionId: params.editorSessionId },
		// @ts-ignore
		specificEnvFeaturesLoaders: createFeaturesLoader(featuresLoaders, { experiments, logger }),
		componentLibraries,
		experiments,
		browserWindow: window,
		BaseComponent: Div,
	}

	logger.phaseStarted(LOADING_PHASES.INIT_DS_CONTAINER)
	const dsAPis = await initThunderboltDS({
		dsCarmi,
		pagesModelsRegistrar,
		environment,
		container,
		wixCustomElements,
		isViewerFragment,
	})
	logger.phaseEnded(LOADING_PHASES.INIT_DS_CONTAINER)

	return dsAPis
}
