export class WixMap {
	array: Array<WeakMap<any, any>>
	static index = 0

	constructor() {
		this.array = new Array(1001)
	}
	static createNewObject() {
		return Object.create({
			hash: WixMap.index++,
		})
	}
	static createNewArray() {
		const newArray: any = []
		const protoArray: any = []
		protoArray.hash = WixMap.index++
		// eslint-disable-next-line no-proto
		newArray.__proto__ = protoArray
		return newArray
	}
	has(key: any) {
		const weakMap = this.getWeakMap(key)
		return weakMap.has(key)
	}
	getWeakMap(key: any) {
		const index = key && key.hash ? key.hash % this.array.length : 0
		if (!this.array[index]) {
			this.array[index] = new WeakMap()
		}
		return this.array[index]
	}
	set(key: any, value: any) {
		const weakMap = this.getWeakMap(key)
		weakMap.set(key, value)
	}
	get(key: any) {
		const weakMap = this.getWeakMap(key)
		return weakMap.get(key)
	}
	delete(key: any) {
		const weakMap = this.getWeakMap(key)
		return weakMap.delete(key)
	}
}
