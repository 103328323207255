import { ProviderCreator } from '@wix/thunderbolt-ioc'
import type { ViewerAPIProvider, IViewerManagerImplementor } from 'thunderbolt-viewer-manager-types'
import type { ViewerAPI } from '@wix/viewer-manager-interface'
import { ViewerManagerApiImplementor } from 'thunderbolt-viewer-manager-types'
import { createDefaultViewerAPI } from './defaultViewerAPI'
import _ from 'lodash'
import { TbDebugSymbol } from 'feature-debug'
import type { IDebugDS } from 'ds-feature-debug-ds'

const FUNCTION = 'function'

const isFunction = (val: any): boolean => typeof val === FUNCTION
const isPromise = (val: any): boolean => val && isFunction(val.then)

export const ViewerManagerProvider: ProviderCreator<ViewerAPIProvider> = (container) => {
	const defaultApis = createDefaultViewerAPI()

	const measuredFn = (debugApi: IDebugDS, fn: Function, name: string): Function => {
		return (...args: Array<any>) => {
			const start = Date.now()
			const entry: any = {
				duration: -1,
				name,
				args,
				res: undefined,
			}
			debugApi.viewerAPIRecorder.add(entry)
			const res = fn(...args)
			entry.res = res
			if (isPromise(res)) {
				res.then((r: any) => {
					entry.res = r
					entry.duration = Date.now() - start
				}).catch((e: any) => {
					entry.res = { rejectedPromiseError: e }
					entry.duration = Date.now() - start
				})
			} else {
				entry.duration = Date.now() - start
			}
			return res
		}
	}

	return async () => ({
		getViewerAPI() {
			const allImplementors = container.getAll<
				IViewerManagerImplementor<keyof ViewerAPI, keyof ViewerAPI[keyof ViewerAPI]>
			>(ViewerManagerApiImplementor)

			let debugApi: IDebugDS | null = null
			try {
				debugApi = container.get(TbDebugSymbol)
			} catch (e) {}

			return allImplementors.reduce<ViewerAPI>(
				(apis, implementor) =>
					_.keys(implementor).reduce(
						(acc, namespace) => ({
							...acc,
							[namespace]: {
								...acc[namespace as keyof ViewerAPI],
								..._.mapValues(implementor[namespace as keyof ViewerAPI], (fn: any, key: string) => {
									return debugApi && isFunction(fn)
										? measuredFn(debugApi, fn, `${namespace}.${key}`)
										: fn
								}),
							},
						}),
						apis
					),
				defaultApis as ViewerAPI
			)
		},
	})
}
