declare const window: DsApiFactoryEnv['window']
import { WixMap } from './WixMap'

window.WixMap = WixMap
import _ from 'lodash'
import React from 'react'
import * as imageKit from '@wix/image-kit'
import ReactDOM from 'react-dom'
import { Container } from '@wix/thunderbolt-ioc'
import { LOADING_PHASES } from '@wix/thunderbolt-symbols'
import type { GetViewerApiParams, ViewerAPI } from '@wix/viewer-manager-interface'
import {
	buildGetViewerFragment,
	buildGetViewerFragmentOnPage,
	buildGetViewerFragments,
	convertToGetViewerFragmentsType,
} from './viewerFragmentRenderer'
import type { DsApiFactoryEnv, DsApis } from './getDsApis'
import { getDsApis } from './getDsApis'
import { createLogger } from './logger/dsLogger'
import schemas from '@wix/document-services-json-schemas/dist/refData.json'
import { isExperimentOpen } from '@wix/thunderbolt-commons'

// TODO Or Granit 29/09/2020: separate to stand alone features
import './style/ds.global.scss' // Only import it so it will be written in manifest.json
import { getEditorNameFromEditorParams } from './helper'

export type DSViewerApiFactoryParams = GetViewerApiParams & { origin?: string } // TODO: remove origin from here when DM merge it into GetViewerApiParams

const dsRunner = () => {
	// for editor-elements-registry
	window.define!('lodash', [], () => _)
	window.define!('reactDOM', [], () => ReactDOM)
	window.define!('react', [], () => React)
	window.define!('imageClientApi', [], () => imageKit)

	window.externalsRegistry = {
		react: {
			loaded: Promise.resolve(),
			onload: _.noop,
		},
		lodash: {
			loaded: Promise.resolve(),
			onload: _.noop,
		},
		reactDOM: {
			loaded: Promise.resolve(),
			onload: _.noop,
		},
		imageClientApi: {
			loaded: Promise.resolve(),
			onload: _.noop,
		},
		clientSdk: {
			loaded: Promise.resolve(),
			onload: _.noop,
		},
	}
	window.resolveExternalsRegistryModule = (name) => window.externalsRegistry[name].onload()
	window.initialTimestamps = {
		initialTimestamp: 0,
		initialRequestTimestamp: 0,
	}

	const viewerManagerModulePromise = import(
		'thunderbolt-viewer-manager' /* webpackChunkName: "thunderboltViewerManager" */
	)
	const componentsPreviewRegistryCSRPromise = import(
		'@wix/thunderbolt-components-registry/preview' /* webpackChunkName: "thunderboltComponentsRegistryPreview" */
	)
	const createStylableFactoryPromise = import(
		'./createStylableFactory' /* webpackChunkName: "createStylableFactory" */
	)

	window.getViewerApi = async (params: DSViewerApiFactoryParams) => {
		const { runningExperiments, viewMode, isResponsive, origin } = params
		const logger = createLogger({
			params: {
				...params,
				isViewerFragment: false,
			},
		})
		logger.phaseStarted(LOADING_PHASES.GET_VIEWER_API)
		const initializeDsApis = (isViewerFragment: boolean) =>
			getDsApis(params, {
				window,
				logger: isViewerFragment ? createLogger({ params: { ...params, isViewerFragment: true } }) : logger,
				container: Container(),
				createComponentsPreviewRegistryPromise: componentsPreviewRegistryCSRPromise,
				viewerManagerModulePromise,
				createStylableFactoryPromise,
				isViewerFragment,
				schemas,
				viewMode,
			})

		const apis = await initializeDsApis(false)

		const fragmentStates: Array<Promise<DsApis>> = []
		const getFragmentDsApis = () =>
			viewMode === 'mobile' ? initializeDsApis(true) : fragmentStates.pop() || initializeDsApis(true)
		const miniSites: ViewerAPI['miniSites'] = {
			getViewerFragment: buildGetViewerFragment(getFragmentDsApis, logger),
			getViewerFragments: isExperimentOpen('specs.thunderbolt.miniSites_runPlatformOnPage', runningExperiments)
				? convertToGetViewerFragmentsType(buildGetViewerFragmentOnPage(getFragmentDsApis, logger))
				: buildGetViewerFragments(getFragmentDsApis, logger),
			// @ts-ignore
			prepareFragmentStates: async (numFragments: number) => {
				for (let i = fragmentStates.length; i < numFragments; i++) {
					fragmentStates.push(initializeDsApis(true))
				}
				await Promise.all(fragmentStates)
			},
			getNumUnusedFragmentStates: () => fragmentStates.length,
		}

		if (isResponsive) {
			const body = document.getElementsByTagName('body')[0] as HTMLElement
			body.classList.add('responsive')
		}
		if (getEditorNameFromEditorParams(origin) === 'Studio') {
			const div = document.createElement('div')
			div.style.overflowY = 'scroll'
			div.style.width = '50px'
			div.style.height = '50px'
			div.style.visibility = 'hidden'
			document.body.appendChild(div)
			const scrollbarWidth = div.offsetWidth - div.clientWidth
			document.body.removeChild(div)
			document.body.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`)
		}

		const target = document.getElementById('SITE_CONTAINER') as HTMLElement
		logger.phaseStarted(LOADING_PHASES.INITIAL_DS_RENDER)
		await apis.render({ target })
		apis.appDidMount()
		logger.phaseEnded(LOADING_PHASES.INITIAL_DS_RENDER)
		apis.initCustomElements()

		const viewerAPI = { ...apis.getViewerAPI(), miniSites }

		logger.phaseEnded(LOADING_PHASES.GET_VIEWER_API)

		return viewerAPI
	}
}

dsRunner()
